import axios from 'axios';
import store from '@/store';
import { getToken, removeToken } from '@/utils/auth'
import { MessageBox, Message } from 'element-ui'

import Router from '@/router/index'

axios.defaults.withCredentials = true;

// 请求拦截
axios.interceptors.request.use(
  (confing) => {
    if (store.getters.token) {
      confing.headers['cmsuToken'] = getToken()
    }
    return confing;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截
axios.interceptors.response.use(
  (response) => {

    if (response.data.code !== 200) {
      Message({
        message: response.data.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // Router.push('/login');
      return Promise.reject(new Error(response.data.message || 'Error'))
    } else {
      return response;
    }
  },
  (error) => {
    // console.log('err' + error);
    if (error.response.data.code === 401) {
      Message({
        message: '用户名或密码错误',
        type: 'error',
        duration: 5 * 1000
      })
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }

    removeToken();
    Router.push('/login');
    return Promise.reject(error);
  }
);
export default axios;