import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/index',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: '/advertising',
        component: () => import('@/pages/advertising/index.vue'),
        meta: { title: '广告位管理' }
      },
      {
        path: '/list',
        component: () => import('@/pages/journalism/list.vue'),
        meta: { title: '新闻列表' }
      },
      {
        path: '/sort',
        component: () => import('@/pages/journalism/sort.vue'),
        meta: { title: '新闻分类' }
      },
      {
        path: '/message',
        component: () => import('@/pages/message/index.vue'),
        meta: { title: '企业信息管理' }
      },
      {
        path: '/userSetting',
        component: () => import('@/pages/userSetting/index.vue'),
        meta: { title: '个人设置' }
      },
      {
        path: '',//二级路由重定向
        redirect: '/message'
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/login.vue'),
  },
  {
    path: '*',
    redirect: '/login',
  },
]

const createRouter = () => new VueRouter({
  scrollBehavior: () => ({ y: 0 }),//解决跳转时新页面没有置顶
  base: process.env.BASE_URL,
  routes: constantRoutes,
})

const router = createRouter()

router.beforeEach((to, from, next) => {
  let token = getToken();
  if (to.path == '/login') {
    if (token) {
      next('/message');
    } else {
      next();
    }
  } else {
    if (token) {
      next();
    } else {
      next('/login');
    }
  }
})

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher // reset router
}


export default router
