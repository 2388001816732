import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 全局引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


// 引入全局清除样式
import '@/assets/css/reset.css'

import preventReClick from '@/assets/js/preventRepeatClick.js';
Vue.use(preventReClick)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
