import axios from './http';
import base from './base';

/**
 * post方法，对应post请求
 * @desc注册请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

// ============================用户接口============================
// 用户登录
export function login(params) {
  return axios({
    url: `${base.url}/jishuiyun-cms/userAuth/login`,
    method: 'post',
    data: params
  });
}
// 获取用户信息
export function getUserInfo(params) {
  return axios({
    url: `${base.url}/jishuiyun-cms/userInfo/getUserInfo`,
    method: 'get',
    params
  });
}
// 修改用户密码
export function updatePassword(params) {
  return axios({
    url: `${base.url}/jishuiyun-cms/userAuth/updatePassword`,
    method: 'post',
    data: params
  });
}
// 用户退出
export function logout(params) {
  return axios({
    url: `${base.url}/jishuiyun-cms/userAuth/logout`,
    method: 'post',
    data: params
  });
}

// 获取验证码
export function getCaptcha(params) {
  return axios({
    url: `${base.url}/jishuiyun-cms/captcha/getCaptcha`,
    method: 'get',
    params
  });
}