<template>
  <div>
    <!-- 路由出口 -->
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  components: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>
<style lang="scss">
.dialog-fade-enter-active .el-dialog.transactionData {
  animation: anim-open .3s linear;
}

.dialog-fade-leave-active .el-dialog.transactionData {
  animation: anim-close .3s linear;
}

@keyframes anim-open {
  0% {
    right: -600px;
  }

  100% {
    right: 0;
  }
}

@keyframes anim-close {
  0% {
    right: 0px;
  }

  100% {
    right: -600px;
  }
}

.el-dialog__wrapper {
  overflow: hidden !important;
}
</style>