if (process.env.NODE_ENV == 'production') {
  var base = {
    url: 'http://cms.ronghuinanhai.com/cms',
  };
} else if (process.env.NODE_ENV == 'development') {
  var base = {
    url: 'http://118.195.226.181:19020',
  };
} else if (process.env.NODE_ENV == 'test') {
  var base = {
    url: 'http://test-cms.ronghuinanhai.com/cms',
  };
}
export default base;